<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Car_owner_name')">
                <el-input
                  v-model.trim="formInline.carOwner"
                  placeholder="请输入车主姓名"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,2"
                ></my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Audit_status')">
                <el-select v-model="formInline.status">
                  <el-option label="全部" value="" />
                  <el-option label="待审核" :value="0" />
                  <el-option label="已驳回" :value="2" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_status')">
                <el-select v-model="formInline.carStatus">
                  <el-option label="全部" value="" />
                  <el-option label="正常" :value="1" />
                  <el-option label="停用" :value="0" />
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.tabs.specialApply.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-refresh-right" @click="resetSearchForm"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.tabs.specialApply.button.add"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.tabs.specialApply.button.add"
          >
            <div class="col_left">
              <el-button
                v-if="$route.meta.authority.tabs.specialApply.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="createApply"
                >创建申请</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.plate_number')"
            align="center"
            prop="plateNumber"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.License_plate_color')"
            align="center"
            prop="plateColorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Vehicle_status')"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.carStatus === 0">
                <span style="color: red">{{ $t("list.Stop_using") }}</span>
              </div>
              <div v-if="scope.row.carStatus === 1">
                {{ $t("list.Enable") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.Car_owner_name')"
            align="center"
            prop="carOwner"
            width="100"
          ></el-table-column>
          <el-table-column
            :label="$t('list.phone_number')"
            align="center"
            prop="mobile"
            width="120"
          ></el-table-column>
          <el-table-column
            :label="$t('list.classification')"
            align="center"
            prop="specialPlateTypeName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Merchant_Name')"
            align="center"
            prop="operationName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Road_name')"
            show-overflow-tooltip
            align="center"
            width="105"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.scope == 1">{{
                $t("list.All_parking_lots")
              }}</span>
              <span v-if="scope.row.scope == 2">{{ scope.row.parkName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.applicant')"
            align="center"
            prop="creatorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Application_Time')"
            align="center"
            prop="createdTime"
            width="170"
          ></el-table-column>
          <el-table-column
            :label="$t('list.reviewer')"
            align="center"
            prop="auditorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Audit_time')"
            align="center"
            prop="auditedTime"
            width="170"
          ></el-table-column>
          <el-table-column
            :label="$t('list.Audit_status')"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.status === 0">
                {{ $t("list.Pending_approval") }}
              </div>
              <div v-if="scope.row.status === 1">{{ $t("list.Passed") }}</div>
              <div v-if="scope.row.status === 2">{{ $t("list.Rejected") }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            width="80"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="$route.meta.authority.tabs.specialApply.button.view"
                    command="a"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="$route.meta.authority.tabs.specialApply.button.update"
                    command="b"
                    >修改</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="$route.meta.authority.tabs.specialApply.button.delete"
                    command="c"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <apply-edit
      ref="applyEdit"
      :dialogStatus="dialogStatus"
      :formData="formData"
      :tenantList="tenantList"
      :plateColorList="plateColorList"
      :specialPlateTypeList="specialPlateTypeList"
      :dialogFormVisible="dialogFormVisible"
      @dialogFormClose="dialogFormClose"
      @querySearchAsync="querySearchAsync"
      @handleSelect="handleSelect"
      @commitConfirm="commitConfirm"
    />
  </div>
</template>

<script>
import ApplyEdit from "./edit";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "whiteListApply",
  components: {
    ApplyEdit,
    myComponent,
  },
  data() {
    return {
      tenantList: [],
      plateColorList: [],
      specialPlateTypeList: [],
      plateNumber: "",
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableData: [],
      formInline: {
        carOwner: "",
        carId: "",
        operationId: "",
        status: "",
        carStatus: "",
        parkId: "",
      },
      formData: {},
      dialogFormVisible: false,
      dialogStatus: "",
      commonKeyList: [],
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  mounted() {
    this.getTenantList();
    this.getPlateColorList();
    this.getSpecialPlateTypeList();
    this.searchData();
  },
  methods: {
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 选择车牌号
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
    // 查询车牌号
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString.toUpperCase(),
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取车牌颜色列表
    getPlateColorList() {
      const opt = {
        url: "/acb/2.0/specialPlateApply/plateColor/list",
        method: "get",
        success: (res) => {
          this.plateColorList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取白名单类型列表
    getSpecialPlateTypeList() {
      const opt = {
        url: "/acb/2.0/specialPlateApply/specialPlateType/list",
        method: "get",
        success: (res) => {
          this.specialPlateTypeList = res.value;
        },
      };
      this.$request(opt);
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/specialPlateApply/list",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          plateType: 0,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = parseInt(res.value.total);
          }
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 清空搜索条件
    resetSearchForm() {
      this.formInline = {
        carOwner: "",
        carId: "",
        operationId: "",
        status: "",
        carStatus: "",
      };
      this.$refs.parkInput.setValue();
      this.plateNumber = "";
      this.pageNum = 1;
      this.searchData();
    },
    // 关闭编辑框
    dialogFormClose() {
      this.dialogFormVisible = false;
    },
    // 确认修改
    commitConfirm(formInline) {
      console.log(formInline, "formInlineformInline");
      // 	 let plateArr = [];
      //   formInline.numberRuleActivities.forEach(val => {
      //     plateArr.push({
      //       plateColor: val.plateColor,
      //       plateNumber: val.numberName + val.plateNumber
      //     })
      //   })
      //   console.log(plateArr, 'plateArr')
      let {
        operationId,
        plateColor,
        plateNumber,
        carOwner,
        mobile,
        specialPlateType,
        carStatus,
        image,
        specialPlateApplyId,
        specialPlateId,
        resourcesId,
        imgList,
        parkIds,
        scope,
      } = formInline;
      let fileList = imgList;
      console.log(
        plateNumber,
        "plateNumber",
        parkIds,
        "parkIds",
        plateColor,
        "plateColor"
      );
      //   plateNumber = plateColor + ':' + plateNumber
      let postData = {
        operationId,
        // plateColor,
        parkIds,
        plateNumber,
        carOwner,
        mobile,
        specialPlateType,
        carStatus,
        plateType: 0,
        specialPlateId,
        scope,
      };
      if (this.dialogStatus === "create" && resourcesId) {
        postData.resourcesId = resourcesId;
      }
      let url = "";
      if (this.dialogStatus === "create") {
        postData.sourceType = 1;
        url = "/acb/2.0/specialPlateApply/create";
      } else if (this.dialogStatus === "edit") {
        postData.specialPlateApplyId = specialPlateApplyId;
        postData.specialPlateId = specialPlateId;
        postData.plateColor = plateColor;
        // postData.parkIds = '1794777593917454288'
        url = "/acb/2.0/specialPlateApply/update";
      }
      if (this.dialogStatus === "create") {
        const opt = {
          url,
          method: "post",
          data: postData,
          success: (res) => {
            this.dialogFormVisible = false;
            if (this.dialogStatus === "create") {
              this.commonKeyList = [];
              for (var i = 0; i < res.value.length; i++) {
                this.commonKeyList.push(res.value[i].resourcesId);
              }
            }
            if (!image || image.length == 0) {
              this.$alert("操作成功");
              this.$refs.applyEdit.resetFields();
              this.searchData();
              return;
            }
            const uploadImg = [];
            this.commonKeyList.forEach((n) => {
              fileList.forEach((item, index) => {
                if (item.file) {
                  uploadImg.push(
                    this.uploadPicture(n, item.file, item.oldIndex || index + 1)
                  );
                }
              });
            });
            Promise.all(uploadImg)
              .then(() => {
                this.$alert("操作成功");
                this.$refs.applyEdit.resetFields();
                this.searchData();
              })
              .catch((err) => {
                this.$alert("操作失败");
              });
          },
          fail: (err) => {
            this.dialogFormVisible = false;
          },
        };
        this.$request(opt);
      } else {
        const opt = {
          url,
          method: "post",
          data: postData,
          success: (res) => {
            this.dialogFormVisible = false;
            this.$alert("操作成功");
            this.$refs.applyEdit.resetFields();
            this.searchData();
          },
          fail: (err) => {
            this.dialogFormVisible = false;
          },
        };
        if (!image || image.length == 0) {
          postData.imgKey = "";
          this.$request(opt);
          return;
        }
        if (fileList.length == 1) {
          this.uploadPicture(
            resourcesId,
            fileList[0].file,
            fileList[0].oldIndex || 1
          ).then((res) => {
            postData.imgKey = res.value;
            this.$request(opt);
          });
        }
        if (fileList.length == 2) {
          this.uploadPicture(
            resourcesId,
            fileList[0].file,
            fileList[0].oldIndex || 1
          ).then((res) => {
            postData.imgKey = res.value;
            this.uploadPicture(
              resourcesId,
              fileList[1].file,
              fileList[1].oldIndex || 2
            ).then(() => {
              this.$request(opt);
            });
          });
        }
      }
    },
    // 上传图片
    uploadPicture(commonKey, img, index) {
      return new Promise((resolve, reject) => {
        const image = new FormData();
        image.append("image", img);
        const opt = {
          url:
            "/acb/2.0/specialPlateApply/upload?commonKey=" +
            commonKey +
            "&imageNo=" +
            index,
          method: "post",
          data: image,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          success: (res) => {
            resolve(res);
          },
        };
        this.$request(opt);
      });
    },
    // 创建申请
    createApply() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      // this.$refs.applyEdit.resetFields()
    },
    // 操作
    handleCommand(event, row) {
      const { specialPlateApplyId } = row;
      switch (event) {
        case "a":
          this.dialogFormVisible = true;
          this.getSpecialPlateApplyDetail(specialPlateApplyId);
          this.dialogStatus = "check";
          break;
        case "b":
          this.dialogFormVisible = true;
          this.getSpecialPlateApplyDetail(specialPlateApplyId);
          this.dialogStatus = "edit";
          break;
        case "c":
          this.deleteItem(specialPlateApplyId);
          break;
        default:
          break;
      }
    },
    // 查询申请详情
    getSpecialPlateApplyDetail(specialPlateApplyId) {
      const opt = {
        url: "/acb/2.0/specialPlateApply/getById",
        method: "get",
        data: {
          specialPlateApplyId,
          plateType: 0,
        },
        success: (res) => {
          const { resourcesId } = res.value;
          // 根据id查询图片
          const option = {
            url: "/acb/2.0/specialPlateApply/getImage",
            method: "get",
            data: {
              commonKey: resourcesId,
            },
            success: (response) => {
              const value = JSON.parse(JSON.stringify(response.value));
              const newData = Object.assign({}, res.value);
              newData.fileList = [{ url: value[1] }, { url: value[2] }];
              this.formData = newData;
            },
          };
          this.$request(option);
        },
      };
      this.$request(opt);
    },
    // 删除
    deleteItem(specialPlateApplyId) {
      this.$confirm("确认是否删除该记录！", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const opt = {
            url: "/acb/2.0/specialPlateApply/delete",
            method: "post",
            data: {
              specialPlateApplyId,
              plateType: 0,
            },
            success: (res) => {
              this.$alert(res.value);
              this.searchData();
            },
          };
          this.$request(opt);
        })
        .catch((err) => {});
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding 22px 22px 0
    // margin-bottom 20px
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
