<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose"
    >
      <el-form ref="dialogForm" :model="formInline" :rules="rules" label-width="120px">
        <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
          <el-select
            v-model.trim="formInline.operationId"
            @change="queryParkList"
            filterable
            size="15"
            :disabled="dialogStatus !== 'create'"
          >
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用范围" prop="scope" v-if="dialogStatus != 'check'">
          <!-- <div
            v-show="isDisabled"
            style="width: 100%; height: 155px; position: absolute; z-index: 2"
          ></div> -->
          <el-select
            :disabled="dialogStatus !== 'create'"
            v-model="formInline.scope"
            placeholder="请选择"
          >
            <el-option label="全部道路" :value="1"></el-option>
            <el-option label="部分道路" :value="2"></el-option>
          </el-select>
          <span class="inputTip">*仅限商户下道路</span>
          <div v-if="formInline.scope == 2" style="margin-bottom: 20px"></div>
          <el-form-item prop="parkIds" v-if="dialogStatus !== 'check'">
            <el-transfer
              target-order="push"
              :titles="['未选择', '已选择']"
              v-if="formInline.scope == 2"
              filterable
              :props="{
                key: 'parkId',
                label: 'parkName',
              }"
              filter-placeholder="请输入道路名称"
              v-model="selected"
              :data="parkList"
            >
            </el-transfer>
          </el-form-item>
        </el-form-item>
        <el-form-item label="适用范围" v-if="dialogStatus == 'check'">{{
          formInline.scope == 2 ? formInline.parkName : "全部道路"
        }}</el-form-item>
        <el-form-item :label="$t('searchModule.License_plate_color')" prop="plateColor" v-if="dialogStatus !== 'create'">
          <el-select v-model="formInline.plateColor" :disabled="dialogStatus !== 'create'">
            <el-option
              v-for="item in plateColorList"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" v-if="dialogStatus !== 'create'">
          <el-input
            v-model.trim="formInline.plateNumber"
            maxlength="10"
            style="width: 220px"
            :disabled="dialogStatus !== 'create'"
          ></el-input>
          <!-- <el-button v-if="dialogStatus === 'create'" type="primary" style="margin-left: 10px" @click="fetchData">{{ $t('button.search') }}</el-button> -->
        </el-form-item>
        <el-form-item
          v-else
          label="车牌号码"
          :required="true"
          :key="ind"
          v-for="(val, ind) in formInline.numberRuleActivities"
        >
          <el-form-item
            :prop="'numberRuleActivities.' + ind + '.numberName'"
            :rules="[{ required: true, message: '请选择车牌号码', trigger: 'change' }]"
            style="width: 100px; display: inline-block"
          >
            <el-select v-model="val.numberName" placeholder="请选择">
              <el-option
                :disabled="item.disabled && item.disabled"
                v-for="item in platerNumberLists"
                :key="item.numberId"
                :label="item.numberName"
                :value="item.numberName"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :prop="'numberRuleActivities.' + ind + '.plateNumber'"
            :rules="[
              { required: true, message: '请输入车牌号码', trigger: 'change' },
              { required: true, validator: checkNumber, trigger: 'change' },
            ]"
            style="width: 210px; padding: 0 20px 0 5px; display: inline-block"
          >
            <el-input
              :disabled="!parkCardIsActive && !isNowDate"
              :maxlength="7"
              v-model.trim="val.plateNumber"
              placeholder="请输入车牌号"
            ></el-input>
          </el-form-item>
          车牌颜色
          <el-form-item
            :prop="'numberRuleActivities.' + ind + '.plateColor'"
            :rules="[{ required: true, message: '请选择车牌颜色', trigger: 'change' }]"
            style="width: 100px; display: inline-block"
          >
            <el-select
              :disabled="!parkCardIsActive && !isNowDate"
              v-model.trim="val.plateColor"
              ref="optionRef"
              placeholder="请选择"
              @change="changePlate(val, val.plateColor, ind)"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button class="button-style" v-if="ind == 0" type="primary" plain @click="addNumber">
            <i class="el-icon-plus icon-style"></i>
          </el-button>
          <el-button class="button-style" v-else type="warning" plain @click="deleteNumber(ind)">
            <i class="el-icon-minus icon-style"></i>
          </el-button>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Car_owner_name')" prop="carOwner">
          <el-input
            v-model="formInline.carOwner"
            maxlength="10"
            style="width: 220px"
            :disabled="dialogStatus === 'check'"
          />
        </el-form-item>
        <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
          <el-input
            v-model="formInline.mobile"
            maxlength="11"
            style="width: 220px"
            :disabled="dialogStatus === 'check'"
          />
        </el-form-item>
        <el-form-item :label="$t('searchModule.classification')" prop="specialPlateType">
          <el-select v-model="formInline.specialPlateType" :disabled="dialogStatus === 'check'">
            <el-option
              v-for="item in specialPlateTypeList"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌状态" prop="carStatus">
          <el-select v-model="formInline.carStatus" :disabled="dialogStatus === 'check'">
            <el-option label="启用" :value="1" />
            <el-option label="停用" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="fileList">
          <el-upload
            action
            list-type="picture-card"
            :on-remove="handleRemove"
            :http-request="handleFiles"
            :file-list="formInline.fileList"
            :class="{ hide: hideUploadEdit }"
            :disabled="dialogStatus == 'check'"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormClose">取 消</el-button>
        <el-button v-if="dialogStatus !== 'check'" type="primary" @click="commitConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const scopeRule = (rule, value, callback) => {
      if (this.formInline.scope == 2) {
        if (value.length < 1) {
          callback(new Error("请选择道路"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      parkCardIsActive: true,
      isNowDate: true,
      parkList: [],
      selected: [],
      formInline: {
        scope: "",
        operationId: "",
        // plateColor: '',
        // plateNumber: '',
        numberRuleActivities: [
          {
            numberId: "",
            plateNumber: "",
            plateColor: "",
          },
        ],
        carOwner: "",
        mobile: "",
        specialPlateType: "",
        carStatus: "",
        fileList: [],
        image: [],
        parkIds: "",
      },
      platerNumberLists: [
        {
          numberId: "11",
          numberName: "京",
        },
        {
          numberId: "12",
          numberName: "津",
        },
        {
          numberId: "13",
          numberName: "冀",
        },
        {
          numberId: "14",
          numberName: "晋",
        },
        {
          numberId: "15",
          numberName: "蒙",
        },
        {
          numberId: "21",
          numberName: "辽",
        },
        {
          numberId: "22",
          numberName: "吉",
        },
        {
          numberId: "23",
          numberName: "黑",
        },
        {
          numberId: "31",
          numberName: "沪",
        },
        {
          numberId: "32",
          numberName: "苏",
        },
        {
          numberId: "33",
          numberName: "浙",
        },
        {
          numberId: "34",
          numberName: "皖",
        },
        {
          numberId: "35",
          numberName: "闽",
        },
        {
          numberId: "36",
          numberName: "赣",
        },
        {
          numberId: "37",
          numberName: "鲁",
        },
        {
          numberId: "41",
          numberName: "豫",
        },
        {
          numberId: "42",
          numberName: "鄂",
        },
        {
          numberId: "43",
          numberName: "湘",
        },
        {
          numberId: "44",
          numberName: "粤",
        },
        {
          numberId: "45",
          numberName: "桂",
        },
        {
          numberId: "46",
          numberName: "琼",
        },
        {
          numberId: "50",
          numberName: "渝",
        },
        {
          numberId: "51",
          numberName: "川",
        },
        {
          numberId: "52",
          numberName: "黔",
        },
        {
          numberId: "53",
          numberName: "滇",
        },
        {
          numberId: "54",
          numberName: "藏",
        },
        {
          numberId: "61",
          numberName: "陕",
        },
        {
          numberId: "62",
          numberName: "甘",
        },
        {
          numberId: "63",
          numberName: "青",
        },
        {
          numberId: "64",
          numberName: "宁",
        },
        {
          numberId: "65",
          numberName: "新",
        },
        {
          numberId: "71",
          numberName: "台",
        },
        {
          numberId: "81",
          numberName: "港",
        },
        {
          numberId: "82",
          numberName: "澳",
        },
        {
          numberId: "90",
          numberName: "外",
        },
      ],
      rules: {
        operationId: [{ required: true, message: "请选择所属商户", trigger: "change" }],
        scope: [
          {
            required: true,
            message: "请选择适用范围",
            trigger: "blur",
          },
        ],
        parkIds: [{ validator: scopeRule, trigger: "blur" }],
        // plateColor: [
        //   { required: true, message: '请选择车牌颜色', trigger: 'change' }
        // ],
        // plateNumber: [
        //   { required: true, message: '请输入车牌号', trigger: 'blur' },
        //   { min: 3, max: 11, message: '长度在 3 到 11 个字符', trigger: 'blur' }
        // ],
        carOwner: [{ required: true, message: "请输入车主姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        specialPlateType: [{ required: true, message: "请选择分类", trigger: "change" }],
        carStatus: [{ required: true, message: "请选择车牌状态", trigger: "change" }],
        fileList: [{ required: true, message: "请选择图片", trigger: "change" }],
      },
      hideUploadEdit: false,
      imgUploadIndex: [],
      title: "",
    };
  },
  props: {
    dialogFormVisible: {
      default: function () {
        return false;
      },
    },
    formData: {
      default: function () {
        return {};
      },
    },
    dialogStatus: {
      default: function () {
        return {};
      },
    },
    tenantList: {
      default: function () {
        return {};
      },
    },
    plateColorList: {
      default: function () {
        return {};
      },
    },
    specialPlateTypeList: {
      default: function () {
        return {};
      },
    },
  },
  watch: {
    // formData: {
    //   handler () {
    //     this.formInline = this.formData
    //     this.hideUploadEdit = this.formInline.fileList.length >= 2
    //   }
    // },
    formData() {
      //   this.formInline = this.formData
      this.formInline.operationId = this.formData.operationId;
      this.formInline.carOwner = this.formData.carOwner;
      this.formInline.mobile = this.formData.mobile;
      this.formInline.specialPlateType = this.formData.specialPlateType;
      this.formInline.carStatus = this.formData.carStatus;
      this.formInline.fileList = this.formData.fileList;
      this.formInline.parkIds = this.formData.parkIds;
      this.formInline.plateNumber = this.formData.plateNumber;
      this.formInline.parkName = this.formData.parkName;
      this.formInline.plateColor = this.formData.plateColor;
      this.formInline.specialPlateApplyId = this.formData.specialPlateApplyId;
      this.formInline.specialPlateId = this.formData.specialPlateId;
      this.formInline.scope = this.formData.scope;
      this.formInline.resourcesId = this.formData.resourcesId;
      this.formInline.numberRuleActivities = [
        {
          numberId: "",
          plateNumber: "",
          plateColor: "",
        },
      ];
      let add = [];
      let parkNames = [];
      if (this.formData.parks && this.formData.parks.length > 0) {
        this.formData.parks.forEach((element) => {
          add.push(element.parkId);
          parkNames.push(element.parkName);
        });
      }
      if (this.formInline.operationId) {
        this.queryParkList();
      }
      this.parkNameList = parkNames;
      this.selected = add;
      if (!this.formInline.image) {
        this.formInline.image = [];
      }
      this.imgUploadIndex = [];
      this.hideUploadEdit = this.formInline.fileList.length >= 2;
    },
    dialogStatus(val) {
      switch (val) {
        case "create":
          this.title = "添加白名单";
          break;
        case "edit":
          this.title = "编辑白名单";
          break;
        case "check":
          this.title = "查看白名单";
          break;
        default:
          break;
      }
    },
  },
  created() {
    // 获取车牌颜色
    this.getColor();
  },
  methods: {
    // 查询道路列表
    queryParkList(val) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            dataSource: 1,
            slaveRelations: "0,2",
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc);
            return;
          }
          this.parkList = res.value;
        });
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    checkNumber(rule, value, callback) {
      let rel = "";
      this.formInline.numberRuleActivities.forEach((el) => {
        if (value == el.plateNumber && (el.numberId == 0 || el.numberId == 1)) {
          // 蓝牌 黄牌
          rel = /^[A-Z]{1}[A-Z0-9]{5}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else if (value == el.plateNumber && (el.numberId == 2 || el.numberId == 3)) {
          console.log("23", el);
          // 2绿牌、3黄绿牌
          rel = /^[A-Z]{1}[A-Z0-9]{6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else if (value == el.plateNumber && (el.numberId == 4 || el.numberId == 5)) {
          console.log("45", el);
          // 4黑牌、5白牌
          rel = /^[A-Z]{1}[A-Z0-9]{5,6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        }
      });
      // if (value !== "") {
      //   callback(new Error('车牌号不能为空'));
      // }
    },

    changePlate(val, color, i) {
      val.numberId = color;
      val.plateColor = color;
    },
    addNumber() {
      if (this.formInline.numberRuleActivities.length < 3) {
        this.formInline.numberRuleActivities.push({
          numberId: "",
          plateNumber: "",
          plateColor: "",
        });
      } else {
        this.$message({
          type: "info",
          message: "最多添加3辆",
        });
        return false;
      }
    },
    deleteNumber(ind) {
      let numberRuleActivities = this.formInline.numberRuleActivities;
      numberRuleActivities.splice(ind, 1);
    },
    // 确认提交
    commitConfirm() {
      let plateArr = [];
      if (this.dialogStatus === "create") {
        this.formInline.numberRuleActivities.forEach((val) => {
          plateArr.push(val.plateColor + ":" + val.numberName + val.plateNumber);
        });
        this.formInline.plateNumber = plateArr.join(";");
      }
      this.formInline.parkIds = this.selected.join(",");
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          if (this.formInline.fileList.length < 2) {
            this.$alert("请将图片上传完整");
            return;
          }
          this.formInline.imgList = this.formInline.fileList.filter(elem => elem.file);
          this.$emit("commitConfirm", this.formInline);
        }
      });
    },
    // 关闭弹窗
    dialogFormClose() {
      this.resetFields();
      this.selected = [];
      this.parkList = [];
      this.formInline.scope = "";
      this.$emit("dialogFormClose");
    },
    // 关闭弹窗
    beforeClose() {
      this.dialogFormClose();
    },
    // 删除图片
    // handleRemove(file, fileList) {
    //   // this.formInline.fileList = fileList
    //   this.formInline.fileList = []
    //   this.formInline.image = []
    //   this.hideUploadEdit = this.formInline.fileList.length >= 2
    // },
    handleRemove(file, fileList) {
      for (let i = 0; i < this.formInline.fileList.length; i++) {
        const item = this.formInline.fileList[i];
        if (item.uid == file.uid) {
          this.imgUploadIndex.push(i + 1);
          break;
        }
      }
      // this.formInline.fileList = fileList
      const images = [];
      this.formInline.fileList = fileList;
      fileList.forEach((ele) => {
        if (ele.file) {
          images.push(ele.file);
        }
      });
      this.formInline.image = images;
      // this.formInline.image = []
      this.hideUploadEdit = this.formInline.fileList.length >= 2;
    },
    // 选中图片
    // handleFiles (file) {
    //   const fileItem = URL.createObjectURL(file.file)
    //   this.formInline.fileList.push({ url: fileItem })
    //   this.formInline.image.push(file.file)
    //   this.hideUploadEdit = this.formInline.fileList.length >= 2
    // },
    handleFiles(file) {
      const fileItem = URL.createObjectURL(file.file);
      let fileListItem = { url: fileItem, file: file.file };
      if (this.imgUploadIndex.length == 1) {
        fileListItem.oldIndex = this.imgUploadIndex[0];
      } else if (this.imgUploadIndex.length == 2) {
        this.formInline.fileList.forEach((item) => {
          item.oldIndex = "";
        });
      }
      this.formInline.fileList.push(fileListItem);
      this.formInline.image.push(file.file);
      this.hideUploadEdit = this.formInline.fileList.length >= 2;
    },
    // 表单恢复默认值
    resetFields() {
      this.$refs["dialogForm"].resetFields();
      this.formInline.image = [];
      this.selected = [];
      this.parkList = [];
      this.hideUploadEdit = false;
    },
    // 查询数据
    fetchData() {
      const { operationId, plateColor, plateNumber } = this.formInline;
      if (!operationId || plateColor === undefined || plateColor === "" || !plateNumber) {
        this.$alert("请选择所属商户及车牌颜色，填写车牌号");
        return;
      }
      let parkIds = "";
      if (this.selected && this.selected.length > 0) {
        parkIds = this.selected.join(",");
      }
      const opt = {
        url: "/acb/2.0/specialPlateApply/check",
        method: "get",
        data: {
          operationId,
          plateColor,
          plateNumber,
          plateType: 0,
          parkId: parkIds,
        },
        success: (res) => {
          if (!res.value) {
            this.$alert("没找到白名单记录，请手动输入");
            return;
          }
          const value = res.value;
          this.formInline.operationId = value.operationId;
          this.formInline.plateColor = value.plateColor;
          this.formInline.plateNumber = value.plateNumber;
          this.formInline.carOwner = value.carOwner;
          this.formInline.mobile = value.mobile;
          this.formInline.specialPlateType = value.specialPlateType;
          this.formInline.carStatus = value.carStatus;
          this.formInline.resourcesId = value.resourcesId;
          this.formInline.specialPlateId = value.specialPlateId;
          const commonKey = value.resourcesId;
          const option = {
            url: "/acb/2.0/specialPlateApply/getImage",
            method: "get",
            data: {
              commonKey,
            },
            success: (response) => {
              const value = JSON.parse(JSON.stringify(response.value));
              const newData = Object.assign({}, res.value);
              newData.fileList = [{ url: value[1] }, { url: value[2] }];
              this.formInline = newData;
              this.hideUploadEdit = this.formInline.fileList.length >= 2;
            },
          };
          this.$request(option);
        },
      };
      this.$request(opt);
    },
  },
};
</script>

<style lang="stylus" scoped>
>>>.hide {
  .el-upload--picture-card {
    display: none;
  }
}
</style>
